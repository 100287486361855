import React, {FunctionComponent} from "react"
import {Box, Grid, Paper} from "@material-ui/core";

type OverviewStepProps = {
    key: number,
    index: number,
    value: string
}

const OverviewStep = (props :OverviewStepProps) => {
    
    return (
        <Grid item xs={6} key={props.index}>
            <Paper elevation={3}><Box p={4}>{props.value}</Box></Paper>
        </Grid>
    )
}

export default OverviewStep