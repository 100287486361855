import React, {FunctionComponent} from "react"
import courseService from "../services/course";
import AuthenticatedLayout from "../components/layouts/authenticatedLayout"
import {Container, Grid} from "@material-ui/core";
import OverviewStep from "../components/overview/step";
import OverviewPath from "../components/overview/path";

const MainPage: FunctionComponent = () => {

    const steps = Object.keys(courseService.steps);
    const paths = Object.keys(courseService.paths);

    return (
        <AuthenticatedLayout title="Overview">
            <h2>OLD COURSE STRUCTURE</h2>
            <Container>
                <h1>Paths</h1>

                <Grid container spacing={3}>
                    {paths.map((value, index) => {
                        return (
                            <OverviewPath key={index} index={index} value={value}/>
                        )
                    })}
                </Grid>
            </Container>
            <br/>
            <h1>Steps</h1>
            <Grid container spacing={3}>
                {steps.map((value, index) => {
                    return (
                        <OverviewStep key={index} index={index} value={value}/>
                    )
                })}
            </Grid>
        </AuthenticatedLayout>
    )
}

export default MainPage
