import React, {FunctionComponent} from "react"
import {Box, Card, CardContent, Container, Grid, Paper, Typography} from "@material-ui/core";
import courseService from "../../services/course";
import {AlertType} from "../../models/alert";
import VideoModal from "../videoModal";
import AuthenticatedLayout from "../layouts/authenticatedLayout";


class sectionChoice {
    label: string;
    redirect: string;
    redirectView: boolean;
    action: string;
    countdownTime: number;
    marker: string;
    lessonEnd: boolean;
    steps: string[];
}

type OpeningPathProps = {
    sectionChoice: sectionChoice,
    sectionKey: string
}


const OpeningPath = (props :OpeningPathProps) => {
    function logClick(e) {
        console.log(props.sectionChoice);
        return true;
    }

    function constructVideoSource(step) { //copied from old app
        //$rootScope.currentVideoFile = step;
        const videoBase = 'http://dh5ycesfech6i.cloudfront.net';
        const personKeys = ['eva', 'hailey', 'isabel', 'lauren', 'maya', 'nicki', 'jessica'];
        const openKeys = ['open4noquit.mp4', 'open2.mp4', 'open3.mp4'];
        const jessicaKeys = ['kyb.mp4','bcintro.mp4','gynecog1.mp4','gynecog2.mp4','condoms.mp4'];
        //const localLesson = localStorageService.get('Lesson');
        let videoSubdir;

        if(personKeys.indexOf(props.sectionKey) > -1) {
            videoSubdir = props.sectionKey[0].toUpperCase() + props.sectionKey.slice(1);
        } else if(props.sectionKey.match(/^sti/)) {
            videoSubdir = 'STIs';
        } else if(props.sectionKey === 'birthcontrol') {
            videoSubdir = 'Jessica';
        } else if(props.sectionKey === 'kyb') {
            videoSubdir = 'know_your_body';
        } else if(props.sectionKey.includes('open') || props.sectionKey === 'close') {
            videoSubdir = 'Open';
        }
        else if(props.sectionKey.indexOf('lesson') > -1) {
            if(jessicaKeys.indexOf(step) > -1) {
                videoSubdir = 'Jessica';
            } else if(openKeys.indexOf(step) > -1) {
                videoSubdir = 'Open';
            } else if(props.sectionKey.charAt(0) == 'e' || props.sectionKey.indexOf('makeupes') > -1) {
                videoSubdir = 'eat_smart';
            } else {
                videoSubdir = 'lessons';
            }
        }
        else {
            //throw `Invalid section key! ${props.sectionKey}`;
            console.log(`Invalid section key! ${props.sectionKey}`)
        }

        return {
            src: `${videoBase}/${videoSubdir}/${step}`,
            type: 'video/mp4'
        };
    }

    const stepsMap = props.sectionChoice.steps.map((value, index) => {
        if(value.includes(".mp4")) {
            const videoUrl = constructVideoSource(value).src

            return (<li key={index}>
                <VideoModal videoUrl={videoUrl}/>
                <a target="_blank" href={videoUrl}>{value}</a>
            </li>)
        } else {
            return (<li key={index}>
                <button className="btn-secondary btn disabled" disabled={true} >{value}</button></li>)
        }
    })



    return (
       <div>
           <p><strong>Label:</strong> {props.sectionChoice?.label} </p>
           {props.sectionChoice?.action && <p><strong>action</strong>: {props.sectionChoice?.action}</p>}
           {props.sectionChoice?.countdownTime && <p><strong>countdownTime</strong>: {props.sectionChoice?.countdownTime}</p>}
           {props.sectionChoice?.marker && <p><strong>marker</strong>: {props.sectionChoice?.marker}</p>}
           <p><button className='btn btn-primary' type="button" onClick={logClick}>Log Details</button></p>
           <h6>Steps:</h6>
           <ol>{stepsMap}</ol>
           {props.sectionChoice?.redirect && <h6>Then:</h6>}
           {props.sectionChoice?.redirect && <p><strong>Redirect</strong>: <a href={props.sectionChoice.redirect == "opening" ? "#" + props.sectionKey : "#" + props.sectionChoice?.redirect }>{props.sectionChoice?.redirect}</a></p>}
           {props.sectionChoice?.redirectView && <p><strong>redirectView</strong>: {props.sectionChoice.redirectView ? "true" : "false"}</p>}
           {props.sectionChoice?.lessonEnd && <p><strong>lessonEnd</strong>: {props.sectionChoice.lessonEnd? "true" : "false"}</p>}


           <hr />
       </div>
    )
}

export default OpeningPath