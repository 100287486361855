import React, {FunctionComponent} from "react"
import {Box, Card, CardContent, Grid, Paper, Typography} from "@material-ui/core";
import courseService from "../../services/course";
import OpeningPath from "./opening";
import OverviewStep from "./step";

type OverviewPathProps = {
    key: number,
    index: number,
    value: string
}

const OverviewPath = (props :OverviewPathProps) => {
    function showOpening(openingElement) {
        {openingElement?.label}
    }
    return (
        <Grid item xs={6} key={props.index}>
            <Card>
                <CardContent>
                    <Typography variant="h5" component="h2">
                        {props.value}
                        <a id={props.value} />

                    </Typography>
                    <Typography variant="body2" component="div">
                        {Object.keys(courseService.paths[props.value]).map((value, index) => {
                            return (
                                <div key={index}>
                                    <a id={value == "opening" ? props.value + "_opening" : value} />
                                    <h6>{value}</h6>
                                    <OpeningPath sectionKey={props.value} sectionChoice={courseService.paths[props.value][value]} />
                                </div>
                            )
                        })}
                    </Typography>

                </CardContent>
            </Card>
        </Grid>
    )
}

export default OverviewPath